import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Routes,
} from "react-router-dom";
import ReactDOM from 'react-dom';
import {Page} from "./page";
import {NewAccount} from "./new-account";
import {ForgotPassword} from "./forgot-password";
import {Login} from "./login";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import {MfaCodeEntry} from "./mfa";

Bugsnag.start({
    apiKey: 'e64c49ffcba3b56a406579c102d81a8c',
    plugins: [new BugsnagPluginReact()]
})

function Home() {
    const [branding, setBranding] = useState({ logoUrl: "", logoLabel: "", needAccountLabel: "" });

    useEffect(() => { getBranding(); }, [])

    const isEqa = () => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("eqa")) {
            return urlParams.get("eqa").toLowerCase() === "true";
        }
        return false;
    };

    const getAccount = () => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("accountId")) {
            return urlParams.get("accountId");
        }
        return null;
    };
    
    const getBranding = async () => {
        const response = await fetch("/Home/GetBranding", { method: "GET" });
        if (response.status === 200) {
            setBranding(JSON.parse(await response.text()));
        }
    };

    return (
        <Router>
            <Routes>
                <Route path="/forgot-password" element={
                    <Page LogoUrl={branding.logoUrl} LogoLabel={branding.logoLabel}>
                        <ForgotPassword />
                    </Page>}>
                </Route>
                <Route path="/new-account" element={
                    <Page LogoUrl={branding.logoUrl} LogoLabel={branding.logoLabel}>
                        <NewAccount />
                    </Page>}>
                </Route>
                <Route path="/" element={
                    <Page LogoUrl={branding.logoUrl} LogoLabel={branding.logoLabel}>
                        <Login NeedAccountLabel={branding.needAccountLabel} AccountId={getAccount()} IsEqa={isEqa()} />
                    </Page>}>
                </Route>
                <Route path="/enter-code" element={
                    <Page LogoUrl={branding.logoUrl} LogoLabel={branding.logoLabel}>
                        <MfaCodeEntry IsEqa={isEqa()} />
                    </Page>}>
                </Route>
            </Routes>
        </Router>
    );
}

const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React)

ReactDOM.render(
    <ErrorBoundary>
        <Home />
    </ErrorBoundary>,
    document.getElementById("content")
);