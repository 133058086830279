import React, {useEffect, useState} from "react";
import {Button, Error, FormString, Label, Title} from "./core";
import { faMobileAlt } from "@fortawesome/pro-light-svg-icons";

function MfaCodeEntry() {
    const [code, setCode] = useState("");
    const [email, setEmail] = useState("");
    const [validating, setValidating] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => { getUrlParams(); }, [])

    const getUrlParams = () => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("email")) {
            setEmail(urlParams.get("email"));
        }
        return false;
    };

    const verify = async () => {
        setValidating(true);
        const body = {
            email: email,
            code: code
        };
        const response = await fetch("/Home/ValidateMfaCode", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        });
        if (response.status === 200) {
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has("redirectUrl")) {
                const redirectUrl = decodeURIComponent(urlParams.get("redirectUrl"));
                const token = (await response.text()).split("?")[1].split("=")[1];
                const url = new URL(redirectUrl);
                url.searchParams.append("token", token);
                window.location.href = url.href;
            } else if (urlParams.has("redirectEndpoint")) {
                const redirectEndpoint = decodeURIComponent(urlParams.get("redirectEndpoint"));
                const parts = (await response.text()).split("?");
                const redirectUrl = parts[0];
                const token = parts[1].split("=")[1];
                const url = new URL(redirectUrl);
                url.searchParams.append("token", token);
                url.searchParams.append("redirectEndpoint", redirectEndpoint);
                window.location.href = url.href;
            }
            else {
                window.location.href = await response.text();
            }
        } else {
            setError(await response.text());
            setValidating(false);
        }
    };
    
    const validChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

    return (
        <div>
            <Title Value="Enter code"/>
            <Label Value="Please enter the code displayed on the authenticator app on your phone"/>
            <div style={{paddingBottom: 12}}/>
            <FormString
                Label="Code"
                Icon={faMobileAlt}
                MaxLength={6}
                ValidChars={validChars}
                Location="MfaCodeEntry"
                Value={code}
                OnChange={setCode}
                TestId="mfa_code"/>
            <Error Value={error} TestId="mfa_error"/>
            <Button
                Label={validating ? "Verifying..." : "Verify"}
                Location="MfaCodeEntry"
                Enabled={code.length === 6 && !validating}
                Class="primary"
                OnClick={verify}
                TestId="mfa_submit"/>
        </div>
    );
}

export { MfaCodeEntry }