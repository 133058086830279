import React, {Component} from "react";
import { Button, Error, FormString, Label, Title } from "./core";
import { Link } from 'react-router-dom';
import { faEnvelope } from "@fortawesome/pro-light-svg-icons";

class ForgotPassword extends Component {
    state = {
        email: "",
        error: "",
        sending: false,
        emailSent: false
    };
    resetPassword = async () => {
        this.setState({ sending: true });
        const body = { email: this.state.email };
        const response = await fetch("/ForgotPassword/SendResetEmail", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        });
        if (response.status === 200) {
            this.setState({ sending: false, emailSent: true });
        } else {
            this.setState({ sending: false, emailSent: true });
        }
    };

    changeEmail = (value) => {
        this.setState({ email: value });
    };
    getButtonLabel = () => this.state.sending ? "Sending..." : "Reset password";
    render() {
        if (this.state.emailSent) {
            return (
                <div>
                    <Title Value="Forgot password" />
                    <Label Value="An email has been sent with instructions to reset your password." />
                    <div style={{ paddingTop: 32 }}>
                        <Link to={"/ + window.location.search"} className="pull-right" data-testid="forgotPassword_returnToLogin">
                            Return to login
                        </Link>
                    </div>
                </div>
            );
        }
        return (
            <div>
                <Title Value="Forgot password"/>
                <FormString
                    Label="Email"
                    Icon={faEnvelope}
                    Value={this.state.email}
                    OnChange={this.changeEmail}
                    TestId="forgotPassword_email"/>
                <Label Value="Enter your email and we will send you instructions to reset your password" />
                <Error
                    Value={this.state.error}
                    TestId="forgotPassword_error"/>
                <Button
                    Label={this.getButtonLabel()}
                    Enabled={this.state.email && !this.state.sending}
                    Class="primary"
                    OnClick={this.resetPassword}
                    TestId="forgotPassword_resetPassword"/>
                <div style={{paddingTop: 32}}>
                    <Link to={"/" + window.location.search} className="pull-right" data-testid="forgotPassword_returnToLogin">
                        Return to login
                    </Link>
                </div>
            </div>
        );
    }
}

export { ForgotPassword }