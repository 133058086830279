import React from "react";
import {Logo} from "./core";

function Page(props) {
    return (
        <div>
            <div className="left-panel">
                <Logo
                    Url={props.LogoUrl}
                    Label={props.LogoLabel} />
            </div>
            <div className="right-panel">
                <div className="vertical-center">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export { Page }