import React, {Component} from "react";
import { Button, Error, FormPassword, FormString, Label, Title } from "./core";
import { Link } from 'react-router-dom';
import { faLockAlt, faEnvelope } from "@fortawesome/pro-light-svg-icons";

class Login extends Component {
    state = {
        email: "",
        password: "",
        loggingIn: false,
        info: null,
        error: "", 
        redirectUrl: null
    };

    logIn = async () => {
        this.setState({ loggingIn: true }, async function() {
            const body = {
                email: this.state.email,
                password: this.state.password,
                eqa: this.props.IsEqa,
                account: this.props.AccountId
            };
            const response = await fetch("/Home/Authenticate", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
            });
            if (response.status === 200) {
                const urlParams = new URLSearchParams(window.location.search);
                if (urlParams.has("redirectUrl")) {
                    const redirectUrl = decodeURIComponent(urlParams.get("redirectUrl"));
                    const token = (await response.text()).split("?")[1].split("=")[1];
                    const url = new URL(redirectUrl);
                    url.searchParams.append("token", token);

                    if (urlParams.has("accountId"))
                        url.searchParams.append("accountId", urlParams.get("accountId"));

                    window.location.href = url.href;
                }
                else if (urlParams.has("redirectEndpoint")) {
                    const redirectEndpoint = decodeURIComponent(urlParams.get("redirectEndpoint"));
                    const parts = (await response.text()).split("?");
                    const redirectUrl = parts[0];
                    const token = parts[1].split("=")[1];
                    const url = new URL(redirectUrl);
                    url.searchParams.append("token", token);
                    url.searchParams.append("redirectEndpoint", redirectEndpoint);

                    if (urlParams.has("accountId"))
                        url.searchParams.append("accountId", urlParams.get("accountId"));

                    window.location.href = url.href;
                }
                else {
                    window.location.href = await response.text();
                }
            } else {
                const error = await response.text();
                this.setState({ error: error, loggingIn: false });
            }
        });
    };
    forgotPassword = () => {
        if (typeof this.props.ChangeView === "function") {
            this.props.ChangeView("forgot-password");
        }
    };
    createAccount = () => {
        if (typeof this.props.ChangeView === "function") {
            this.props.ChangeView("new-account");
        }
    };
    changeEmail = (value) => {
        this.setState({ email: value, error: "" });
    };
    changePassword = (value) => {
        this.setState({ password: value, error: "" });
    };
    getButtonLabel = () => {
        if (this.state.loggingIn) {
            return "Logging in...";
        }
        return "Log in";
    };
    isButtonEnabled = () => {
        return this.state.email && this.state.password && !this.state.loggingIn;
    };
    render() {
        return (
            <div>
                <Title Value="Log in"/>
                <FormString
                    Label="Email"
                    Icon={faEnvelope}
                    Location="Login"
                    AutoComplete="email"
                    Value={this.state.email}
                    OnChange={this.changeEmail}
                    TestId="login_email"/>
                <FormPassword
                    Label="Password"
                    Icon={faLockAlt}
                    Location="Login"
                    AutoComplete="password"
                    Value={this.state.password}
                    OnChange={this.changePassword}
                    OnEnterPressed={this.logIn}
                    TestId="login_password" />
                <Error Value={this.state.error} TestId="loginError"/>
                <Button
                    Label={this.getButtonLabel()}
                    Location="Login"
                    Enabled={this.isButtonEnabled()}
                    Class="primary"
                    OnClick={this.logIn}
                    TestId="login_submit"/>
                <div style={{paddingTop: 32}}>
                    <Label Value={this.state.needAccountLabel} />
                    <Link to={"new-account" + window.location.search} className="create-account-link" data-testid="login_createAnAccount">
                        Create an account
                    </Link>
                    <Link to={"forgot-password" + window.location.search} className="pull-right" data-testid="login_forgotPassword">
                        Forgot password
                    </Link>
                </div>
            </div>
        );
    }
}

export { Login }