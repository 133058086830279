import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function FormInput(props) {
    return (
        <div className="input-group">
            <div className="input-group-addon">
                    <span className="input-group-text">
                        <FontAwesomeIcon icon={props.Icon}/>
                    </span>
            </div>
            {props.children}
        </div>
    );
}

function FormString(props) {
    const changeHandler = (value) => {
        if (typeof props.OnChange === "function") {
            props.OnChange(value);
        }
    };
    
    const blurHandler = (value) => {
        if (typeof props.OnBlur === "function") {
            props.OnBlur(value);
        }
    };

    if (props.Visible !== undefined && !props.Visible) {
        return null;
    }
    return (
        <FormInput Id={props.Id} Icon={props.Icon}>
            <InputString
                Id={props.Id}
                AutoFocus={props.AutoFocus}
                AutoComplete={props.AutoComplete}
                Placeholder={props.Label}
                MaxLength={props.MaxLength}
                ValidChars={props.ValidChars}
                Value={props.Value}
                OnChange={changeHandler}
                OnBlur={blurHandler}
                TestId={props.TestId}/>
        </FormInput>
    );
}

function FormPassword(props) {
    const changeHandler = (e) => {
        if (typeof props.OnChange === "function") {
            props.OnChange(e.target.value);
        }
    };
    const blurHandler = (e) => {
        if (typeof props.OnBlur === "function") {
            props.OnBlur(e.target.value);
        }
    };
    const keyUpHandler = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            if (typeof props.OnEnterPressed === "function") {
                props.OnEnterPressed();
            }
        }
    };
    const getAutoComplete = () => props.AutoComplete ? "on" : "new-password";

    if (props.Visible !== undefined && !props.Visible) {
        return null;
    }
    return (
        <FormInput Id={props.Id} Icon={props.Icon}>
            <input
                id={props.Id}
                type="password"
                className="form-control"
                value={props.Value}
                placeholder={props.Label}
                onChange={changeHandler}
                onBlur={blurHandler}
                onKeyUp={keyUpHandler}
                autoComplete={getAutoComplete()}
                data-testid={props.TestId}/>
        </FormInput>
    );
}

function InputString(props) {
    const changeHandler = (e) => {
        if (typeof props.OnChange === "function") {
            props.OnChange(e.target.value);
        }
    };
    
    const blurHandler = (e) => {
        if (typeof props.OnBlur === "function") {
            props.OnBlur(e.target.value);
        }
    };
    
    const filterInvalidChars = (e) => {
        if (e.key === "Backspace" || e.key === "ArrowLeft" || e.key === "ArrowRight") {
            return;
        }
        if (e.key === "Enter") {
            e.preventDefault();
        }
        if (props.ValidChars && !props.ValidChars.includes(e.key)) {
            e.preventDefault();
        }
    };
    
    const getAutocomplete = () => props.AutoComplete || "off";

    if (props.AutoFocus) {
        return (
            <input
                autoFocus
                autoComplete={getAutocomplete()}
                className="form-control"
                id={props.Id}
                maxLength={props.MaxLength}
                onChange={changeHandler}
                onBlur={blurHandler}
                onKeyDown={filterInvalidChars}
                placeholder={props.Placeholder}
                type="text"
                style={props.Style}
                value={props.Value}
                data-testid={props.TestId} />
        );
    } else {
        return (
            <input
                autoComplete={getAutocomplete()}
                className="form-control"
                id={props.Id}
                maxLength={props.MaxLength}
                onChange={changeHandler}
                onBlur={blurHandler}
                onKeyDown={filterInvalidChars}
                placeholder={props.Placeholder}
                type="text"
                style={props.Style}
                value={props.Value}
                data-testid={props.TestId} />
        );
    }
}

function Button(props) {
    const clickHandler = () => {
        if (typeof props.OnClick === "function") {
            props.OnClick();
        }
    };
    const getClass = () => {
        let className = "btn";
        if (props.Class) {
            className = className + " btn-" + props.Class;
        }
        return className;
    };

    if (props.Enabled) {
        return (
            <button
                type="button"
                className={getClass()}
                onClick={clickHandler}
                data-testid={props.TestId}>
                {props.Label}
            </button>
        );
    }
    return (
        <button
            disabled type="button"
            className={getClass()}
            data-testid={props.TestId}>
            {props.Label}
        </button>
    );
}

function Link(props) {
    const clickHandler = () => {
        if (typeof props.OnClick === "function") {
            props.OnClick();
        }
    };
    
    const getClass = () => {
        let className = "cursor";
        if (props.Class) {
            className = className + " " + props.Class;
        }
        return className;
    };

    if (props.Visible === undefined || props.Visible) {
        return (
            <a className={getClass()} onClick={clickHandler} data-testid={props.TestId}>
                {props.Label}
            </a>
        );
    }
    return null;
}

function Label(props) {
    return (
        <label>{props.Value}</label>
    );
}

function Logo(props) {
    return (
        <img src={props.Url} alt={props.Label} className="logo" />
    );
}

function Title(props) {
    return (
        <h1 className="title">{props.Value}</h1>
    );
}

function Error(props) {
    if (props.Value) {
        return (
            <label className="error">{props.Value}</label>
        );
    }
    return null;
}

export { Error, Title, Logo, Label, Link, Button, FormPassword, FormString, FormInput }