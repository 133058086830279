import React, {Component} from "react";
import { Button, Error, FormPassword, FormString, Title } from "./core";
import { Link } from 'react-router-dom';
import { faLockAlt, faEnvelope, faUser, faBuilding, faUnlockAlt } from "@fortawesome/pro-light-svg-icons";

class NewAccount extends Component {
    state = {
        name: "",
        email: "",
        company: "",
        password: "",
        confirmPassword: "",
        ipAddress: "",
        creatingAccount: false,
        error: ""
    };
    createAccount = async () => {
        const urlParams = new URLSearchParams(window.location.search);

        this.setState({ creatingAccount: true });
        const body = {
            name: this.state.name,
            email: this.state.email,
            company: this.state.company,
            password: this.state.password,
            confirmPassword: this.state.confirmPassword,
            ipAddress: this.state.ipAddress
        };
        if (urlParams.has("subscription")) {
            body.subscription = urlParams.get("subscription");
        }
        const response = await fetch("/NewAccount/CreateNewAccount", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        });
        if (response.status === 200) {
            const result = await response.text();
            if (urlParams.has("redirectUrl")) {
                const redirectUrl = decodeURIComponent(urlParams.get("redirectUrl"));
                const token = result.split("?")[1].split("=")[1];
                const url = new URL(redirectUrl);
                url.searchParams.append("token", token);
                window.location.href = url.href;
            }
            else if (urlParams.has("redirectEndpoint")) {
                const redirectEndpoint = decodeURIComponent(urlParams.get("redirectEndpoint"));
                const parts = result.split("?");
                const redirectUrl = parts[0];
                const token = parts[1].split("=")[1];
                const url = new URL(redirectUrl);
                url.searchParams.append("token", token);
                url.searchParams.append("redirectEndpoint", redirectEndpoint);
                window.location.href = url.href;
            }
            else {
                window.location.href = result;
            }
        } else {
            const error = await response.text();
            this.setState({ error: error, creatingAccount: false });
        }
    };
    changeEmail = (value) => {
        this.setState({ email: value, error: "" });
    };
    changeName = (value) => {
        this.setState({ name: value, error: "" });
    };
    changeCompany = (value) => {
        this.setState({ company: value, error: "" });
    };
    changePassword = (value) => {
        this.setState({ password: value, error: "" });
    };
    changeConfirmPassword = (value) => {
        this.setState({ confirmPassword: value, error: "" });
    };
    blurPassword = () => {
        if (this.state.password !== this.state.confirmPassword && this.state.password && this.state.confirmPassword) {
            this.setState({ error: "Passwords do not match" });
        }
        else {
            this.setState({ error: "" });
        }
    };
    getButtonLabel = () => {
        if (this.state.creatingAccount) {
            return "Creating account...";
        }
        return "Get started";
    };
    isButtonEnabled = () => {
        if (this.state.name && this.state.email && this.state.company && this.state.password)
        {
            return this.state.password === this.state.confirmPassword;
        }
        return this.state.error && !this.state.creatingAccount;
    };
    render() {
        return (
            <div>
                <Title Value="Create a new account"/>
                <FormString
                    Label="Name"
                    Icon={faUser}
                    Location="New Account"
                    Value={this.state.name}
                    OnChange={this.changeName}
                    TestId="newAccount_name" />
                <FormString
                    Label="Email"
                    Icon={faEnvelope}
                    Value={this.state.email}
                    OnChange={this.changeEmail}
                    TestId="newAccount_email" />
                <FormString 
                    Label="Company name"
                    Icon={faBuilding}
                    Value={this.state.company}
                    OnChange={this.changeCompany}
                    TestId="newAccount_companyName" />
                <FormPassword
                    Label="Password"
                    Icon={faLockAlt}
                    Value={this.state.password}
                    OnChange={this.changePassword}
                    OnBlur={this.blurPassword}
                    TestId="newAccount_password"/>
                <FormPassword
                    Label="Confirm password"
                    Icon={faUnlockAlt}
                    Value={this.state.confirmPassword}
                    OnChange={this.changeConfirmPassword}
                    OnBlur={this.blurPassword}
                    TestId="newAccount_confirmPassword"/>
                <Error Value={this.state.error}/>
                <Button 
                    Label={this.getButtonLabel()}
                    Enabled={this.isButtonEnabled()}
                    Class="primary"
                    OnClick={this.createAccount}
                    TestId="newAccount_submit"/>
                <div style={{ paddingTop: 32 }}>
                    <Link to={"/" + window.location.search} className="pull-right">
                        Log in with an existing account
                    </Link>
                </div>
            </div>
        );
    }
}

export { NewAccount }